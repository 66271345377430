import React from "react"
import AnchorLink from "react-anchor-link-smooth-scroll"

class MainBanner extends React.Component {
  render() {
    return (
      <div id="home" className="main-banner item-bg-two">
        <div className="creative-banner-one"></div>
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="main-banner-text">
                    <h4>We Are Creative</h4>
                    <h1>
                      The Best <span>Digital</span> Experience
                    </h1>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <AnchorLink href="#welcome" className="btn btn-primary">
                      Get Started
                    </AnchorLink>
                    <AnchorLink href="#work" className="btn btn-primary view-work">
                      View Work
                    </AnchorLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default MainBanner
