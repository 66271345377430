import React from "react"
import VaccineSlots from "../Common/VaccineSlots"

class Blog extends React.Component {
  render() {
    return (
      <React.Fragment>
        <VaccineSlots />
      </React.Fragment>
    )
  }
}

export default Blog
