import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navigation from "./components/Navigation/Navigation";
import Preloader from "./components/Common/Preloader";
import HomeOne from "../src/components/Pages/HomeOne";
import HomeTwo from "../src/components/Pages/HomeTwo";
import HomeThree from "../src/components/Pages/HomeThree";
import HomeFour from "../src/components/Pages/HomeFour";
import HomeFive from "./components/Pages/HomeFive";
import HomeSix from "./components/Pages/HomeSix";
import HomeSeven from "./components/Pages/HomeSeven";
import HomeEight from "./components/Pages/HomeEight";
import HomeNine from "./components/Pages/HomeNine";
import HomeTen from "./components/Pages/HomeTen";
import HomeEleven from "./components/Pages/HomeEleven";
import HomeTwelve from "./components/Pages/HomeTwelve";
import Blog from "./components/Pages/Blog";
import BlogDetails from "./components/Pages/BlogDetails";
import Vaccine from "./components/Pages/Vaccine";

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    demoAsyncCall().then(() => setLoading(false));
  }, []);

  const demoAsyncCall = () => {
    return new Promise((resolve) => setTimeout(() => resolve(), 2000));
  };

  const hashLinkScroll = () => {
    const { hash } = window.location;
    if (hash !== "") {
      setTimeout(() => {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) element.scrollIntoView();
      }, 0);
    }
  };

  const location = window.location.href.split("/").pop();

  return (
    <BrowserRouter>
      <Routes>
        <>
          <Route path="/home-one" exact element={<HomeOne />} />
          <Route path="/home-two" exact element={<HomeTwo />} />
          <Route path="/home-three" exact element={<HomeThree />} />
          <Route path="/home-four" exact element={<HomeFour />} />
          <Route path="/home-five" exact element={<HomeFive />} />
          <Route path="/home-six" exact element={<HomeSix />} />
          <Route path="/home-seven" exact element={<HomeSeven />} />
          <Route path="/home-eight" exact element={<HomeEight />} />
          <Route path="/home-nine" exact element={<HomeNine />} />
          <Route path="/home-ten" exact element={<HomeTen />} />
          <Route path="/home-eleven" exact element={<HomeEleven />} />
          <Route path="/" exact element={<HomeTwelve />} />
          <Route path="/blog" exact element={<Blog />} />
          <Route path="/blog-details" exact element={<BlogDetails />} />
        </>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
